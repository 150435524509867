@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Obviously Wide";
  src: url("../public/Obviously-Wide.woff") format("woff"),
    url("../public/Obviously-Wide.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Obviously Wide";
  src: url("../public/Obviously-Wide_Medium.woff") format("woff"),
    url("../public/Obviously-Wide_Medium.woff2") format("woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Obviously Wide";
  src: url("../public/Obviously-Wide_Semibold.woff") format("woff"),
    url("../public/Obviously-Wide_Semibold.woff2") format("woff2");
  font-weight: 600;
}

@font-face {
  font-family: "Satoshi";
  src: url("../public/Satoshi-Variable.woff2") format("woff2");
  font-weight: 100 900;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
